class MonitorRestarter {
    constructor(monitor) {
        this.shouldWork = false;
        this.timeoutId = null;

        this.monitor = monitor;
        this.monitor.addErrorCallback(this.onError.bind(this));
    }

    /* --- */

    recheckStatus() {
        if (this.timeoutId) {
            return;
        }

        if (this.shouldWork) {
            this.monitor.start();
        } else {
            this.monitor.stop();
        }
    }

    start() {
        this.shouldWork = true;

        this.recheckStatus();
    }

    stop() {
        this.shouldWork = false;

        this.recheckStatus();
    }

    restart() {
        if (this.timeoutId) {
            return;
        }

        this.monitor.stop();

        if (this.shouldWork) {
            this.monitor.start();
        }
    }

    /* --- */

    startRestart() {
        this.stopRestart();

        const seconds = Math.round(Math.random() * 10 + 15);

        this.timeoutId = globalThis.setTimeout(() => {
            globalThis.clearTimeout(this.timeoutId);
            this.timeoutId = null;

            this.restart();
        }, seconds * 1000);
    }

    stopRestart() {
        globalThis.clearTimeout(this.timeoutId);
        this.timeoutId = null;
    }

    onError() {
        if (this.shouldWork) {
            this.startRestart();
        }
    }
}

export default MonitorRestarter;

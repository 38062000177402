export default (apiURL, methods) => {
    return {
        getStandardsByState(params = {}) {
            // NOTE: action=get_standards_by_state
            const url = `${apiURL}/standards/by-state`;

            return methods.get(url, {
                state: params.state,
            });
        },
    };
};

import device from "./device.js";


const getSiteUrl = () => {
    if (window) {
        const proto = window?.location?.protocol || "";
        const hostname = window?.location?.hostname || "";
        return `${proto}//${hostname}`;
    }

    return "";
};

const redirect = (url) => {
    if (window) {
        window.location = url;
    }
};

const reload = () => {
    if (window) {
        window.location.reload(true);
    }
};

const openUrl = (url) => {
    if (window) {
        window.open(url, "_blank");
    }
};

const download = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.click();
};

const universalDownload = (url) => {
    if (device.isSafari) {
        download(url);
    } else {
        openUrl(url);
    }
};

const stripProtocol = (url) => {
    const protocols = [
        "https://",
        "http://",
        "//",
    ];

    for (let i = 0; i < protocols.length; i += 1) {
        const prt = protocols[i];

        if (url.indexOf(prt) === 0) {
            return url.slice(prt.length);
        }
    }

    return url;
};

const getSearchParamByName = (name) => {
    try {
        const url = new URL(window.location); // eslint-disable-line compat/compat
        const param = url.searchParams.get(name);
        return param || "";
    } catch (err) {
        console.error(err); // eslint-disable-line no-console
        return "";
    }
};

export default {
    getSiteUrl,
    redirect,
    reload,
    openUrl,
    download,
    universalDownload,
    stripProtocol,

    getSearchParamByName,
};

class EventSourceWrapper {
    constructor(callbacks) {
        this.isFirstStart = true;

        this.url = "";
        this.callbacks = callbacks;
        this.errorCallback = null;

        this.timeoutId = null;
        this.isConnected = false;
        this.evtSource = null;
    }

    setURL(url) {
        this.url = url;
    }

    addErrorCallback(fn) {
        this.errorCallback = fn || null;
    }

    start() {
        if (!globalThis.EventSource) {
            return;
        }

        this.stop();

        console.log("[SSE]: start"); // eslint-disable-line no-console

        this.evtSource = new globalThis.EventSource(this.url); // eslint-disable-line compat/compat

        this.evtSource.addEventListener("open", this.onOpen.bind(this));
        this.evtSource.addEventListener("error", this.onError.bind(this));
        this.evtSource.addEventListener("message", this.onMessage.bind(this));

        this.evtSource.addEventListener("ping", this.onPing.bind(this));
        this.evtSource.addEventListener("system", this.onSystem.bind(this));
        this.evtSource.addEventListener("update", this.onUpdate.bind(this));
    }

    stop() {
        console.log("[SSE]: stop"); // eslint-disable-line no-console

        this.isConnected = false;

        if (this.evtSource) {
            this.evtSource.close();
            this.evtSource = null;
        }

        if (this.callbacks.onStop) {
            this.callbacks.onStop();
        }
    }

    /* --- */

    parseData(data) { // eslint-disable-line class-methods-use-this
        try {
            const res = JSON.parse(data);
            return res || {};
        } catch (err) {
            console.error("[SSE]: parse error=", err); // eslint-disable-line no-console
            return {};
        }
    }

    /* --- */

    onOpen() {
        this.isConnected = true;

        console.log("[SSE]: open"); // eslint-disable-line no-console

        if (this.callbacks.onStart) {
            this.callbacks.onStart();
        }

        if (this.isFirstStart) {
            this.isFirstStart = false;
            return;
        }

        if (this.callbacks.onUpdateAll) {
            this.callbacks.onUpdateAll();
        }
    }

    onError(err) {
        console.error("[SSE]: error=", err); // eslint-disable-line no-console

        this.stop();

        if (this.errorCallback) {
            this.errorCallback();
        }
    }

    onMessage(evt) { // eslint-disable-line class-methods-use-this
        console.log(`[SSE]: data=${evt.data}`); // eslint-disable-line no-console
    }

    onPing(evt) { // eslint-disable-line class-methods-use-this
        console.log(`[SSE]: type=${evt.type} data=${evt.data}`); // eslint-disable-line no-console
    }

    onSystem(evt) { // eslint-disable-line class-methods-use-this
        console.log(`[SSE]: type=${evt.type} data=${evt.data}`); // eslint-disable-line no-console
    }

    onUpdate(evt) {
        console.log(`[SSE]: type=${evt.type} data=${evt.data}`); // eslint-disable-line no-console

        if (this.callbacks.onUpdate) {
            const data = this.parseData(evt.data);
            this.callbacks.onUpdate(data);
        }
    }
}

export default EventSourceWrapper;

import * as types from "./types.js";


const initialState = {
    siteDate: null,

    isSponsorsLoaded: false,
    sponsors: [],

    features: {
        lmsSignInUrl: "",
    },
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_SITE_DATE: {
            return {
                ...state,
                siteDate: action.payload.date,
            };
        }

        case types.SET_SPONSORS: {
            return {
                ...state,
                isSponsorsLoaded: true,
                sponsors: action.payload.sponsors || {},
            };
        }

        case types.SET_FEATURES: {
            const features = action.payload || {};

            return {
                ...state,
                features: {
                    ...state.features,
                    ...features,
                },
            };
        }

        default: {
            return state || { ...initialState };
        }
    }
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OsklzlSXxycZnZAiStF6 {\n    display: grid;\n    grid-row-gap: 2rem;\n}\n\n/* --- */\n\n.Tw1pitmX1KumYFFrVmG4 {\n    display: grid;\n    grid-auto-flow: column;\n    grid-column-gap: 2rem;\n    grid-template-columns: 1fr 1fr;\n}\n\n.tTpRkJUq_ciA1aSxhkTw {\n    display: flex;\n    flex-direction: column;\n}\n\n.tTpRkJUq_ciA1aSxhkTw > div {\n    margin-bottom: 2rem;\n}\n\n.tTpRkJUq_ciA1aSxhkTw > div:last-child {\n    margin-bottom: 0;\n}\n", "",{"version":3,"sources":["webpack://./base/components/tiles/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA,QAAQ;;AAER;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".tilesMobile {\n    display: grid;\n    grid-row-gap: 2rem;\n}\n\n/* --- */\n\n.tilesDesktop {\n    display: grid;\n    grid-auto-flow: column;\n    grid-column-gap: 2rem;\n    grid-template-columns: 1fr 1fr;\n}\n\n.tilesDesktopCol {\n    display: flex;\n    flex-direction: column;\n}\n\n.tilesDesktopCol > div {\n    margin-bottom: 2rem;\n}\n\n.tilesDesktopCol > div:last-child {\n    margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tilesMobile": "OsklzlSXxycZnZAiStF6",
	"tilesDesktop": "Tw1pitmX1KumYFFrVmG4",
	"tilesDesktopCol": "tTpRkJUq_ciA1aSxhkTw"
};
export default ___CSS_LOADER_EXPORT___;

export const OPEN_VOCABULARY_WORD_POPUP = "OPEN_VOCABULARY_WORD_POPUP";
export const OPEN_AND_SET_VOCABULARY_WORD_POPUP = "OPEN_AND_SET_VOCABULARY_WORD_POPUP";
export const CLOSE_VOCABULARY_WORD_POPUP = "CLOSE_VOCABULARY_WORD_POPUP";
export const SET_VOCABULARY_WORD_POPUP_ALIAS_WORD = "SET_VOCABULARY_WORD_POPUP_ALIAS_WORD";

export const SET_VOCABULARY_WORDS = "SET_VOCABULARY_WORDS";
export const SET_VOCABULARY_WORD_LOADING = "SET_VOCABULARY_WORD_LOADING";
export const SET_VOCABULARY_WORD_ERROR = "SET_VOCABULARY_WORD_ERROR";
export const SET_VOCABULARY_WORD = "SET_VOCABULARY_WORD";
export const CLEAR_VOCABULARY_WORD_BY_NAME = "CLEAR_VOCABULARY_WORD_BY_NAME";

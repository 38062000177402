const getVisibilityEventHandler = (callback) => {
    const pauseInSeconds = 30;

    let timeoutId = null;

    return (evt) => {
        if (evt.target.visibilityState === "hidden") {
            timeoutId = setTimeout(() => {
                callback(false);
            }, pauseInSeconds * 1000);
            return;
        }

        clearTimeout(timeoutId);

        callback(true);
    };
};

export default {
    getVisibilityEventHandler,
};
